import {
  useInitData,
  useWebApp
} from '@vkruglikov/react-telegram-web-app';
import { WebApp } from '@vkruglikov/react-telegram-web-app/lib/core/twa-types';
import { useContext, useEffect, useRef, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { UserContext } from '../contexts/user';
import Pope from '../images/lootboss.png';

import { ReactComponent as Blessings } from '../images/stars.svg';
import { Message } from '../types';
import "./Quest.css";


interface QuestInfo {
  current: number,
  limit: number,
  beat: number,
  running: boolean,
  beat_timeout: number
}

interface QuestData {
  active: boolean
  quest: QuestInfo | undefined
  wait: number | null
}

interface QuestMessage extends Message {
  data: QuestData
}

class DamageLabels {
  private readonly labels: number[][] = [];

  constructor() {
    setInterval(this.cleanLabels.bind(this), 1000);
  }

  private cleanLabels() {
    const now = (new Date()).getTime()

    while (this.labels.length && (now - this.labels[0][0]) > 3000) {
      this.labels.pop()
    }
  }

  getLabels() { return this.labels; }
  addLabel(dt: number, path: number) { this.labels.push([dt, path]); }
}

export const QuestPage = () => {
  const [initDataUnsafe, initData] = useInitData();
  const [isDisabled, setIsDisabled] = useState(false);
  const [progress, setProgress] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [current, setCurrent] = useState(0);
  const [alarm, setAlarm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pctProgress, setPctProgress] = useState(100);
  const [beat, setBeat] = useState(0);
  // const [userBeatCache, setUserBeatCache] = useState(0);
  // const [tick, setTick] = useState(-1);
  const [shadowBalance, setShadowBalance] = useState<number>();
  const [beatTimeout, setBeatTimeout] = useState<number>(4);

  const webApp: WebApp = useWebApp();

  // const beatsRef = useRef(userBeatCache);
  const labels = useRef(new DamageLabels());

  const { id: user_id, balance } = useContext(UserContext);
  // const { mute, setSound, startStopSound, sound, player } =
  //   useContext(ControlContext).music;

  const { sendJsonMessage, readyState, lastJsonMessage } =
    useWebSocket<QuestMessage>(process.env.REACT_APP_WSS_ENDPOINT!, {
      filter: (m) => ['quest'].indexOf(JSON.parse(m.data).method) >= 0,
      share: true,
      shouldReconnect: () => true,
    });

  // enable vibration support
  // @ts-ignore
  navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;

  useEffect(() => {
    if (user_id === 0) return;

    // setShadowBalance((prev) => prev === undefined ? balance : Math.min(prev, balance))

    setShadowBalance(balance);
  }, [user_id, balance])

  // useEffect(() => {
  //   beatsRef.current = userBeatCache;
  // }, [userBeatCache])

  // const sendBeatsByTimer = useCallback(() => {
  //   console.log("Call send by timer", userBeatCache);

  //   if (!sendJsonMessage || !userBeatCache) return;

  //   sendJsonMessage({"method": "quest:tap", "taps": userBeatCache})
  //   setUserBeatCache(0);
  // }, [sendJsonMessage, userBeatCache])

  useEffect(() => {
    if (!webApp || webApp.isClosingConfirmationEnabled === undefined) return;

    if (!webApp.isClosingConfirmationEnabled) {
      webApp.enableClosingConfirmation && webApp.enableClosingConfirmation();
    }
  }, [webApp]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTick((prevState) => prevState + 1);
  //   }, 7500);

  //   sendJsonMessage({"method": "quest"})

  //   return () => {
  //     clearInterval(interval);

  //     if (beatsRef.current !== undefined && beatsRef.current > 0) {
  //       console.log('unmount w/ taps', beatsRef.current);
  //       sendJsonMessage({ method: 'quest:beat', taps: beatsRef.current });
  //       setUserBeatCache(0);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   if (user_id === 0) return;

  //   sendBeatsByTimer();

  //   if (tick % 2 === 0) {
  //     sendJsonMessage({ method: 'quest' });
  //   }
  // }, [user_id, tick])

  // useEffect(() => {
  //   if (user_id === 0) return;
  //   if (userBeatCache < 50) return;

  //   sendBeatsByTimer();
  // }, [user_id, userBeatCache]);

  useEffect(() => {
    if (readyState !== ReadyState.OPEN || user_id === 0) return;

    setLoading(false);
    // setIsDisabled(false);

    sendJsonMessage({ method: 'quest' });
  }, [user_id, sendJsonMessage, readyState]);

  useEffect(() => {
    if (!lastJsonMessage || lastJsonMessage.error) return;

    setLoading(false);

    if (lastJsonMessage.method === 'quest') {
      const data = lastJsonMessage.data;

      if (!data.quest) return;

      setCurrent(data.quest.current);
      setTotal(data.quest.limit);
      setPctProgress(Math.floor(data.quest.current * 100 / data.quest.limit))
      setBeat(data.quest.beat);
      setBeatTimeout(data.quest.beat_timeout);

      if (data.wait !== null) {
        setProgress(true);
        setIsDisabled(true);

        setTimeout(() => {
          setProgress(false);
          setIsDisabled(false);
        }, data.wait * 1000);
      } else {
        setProgress(false);
        setIsDisabled(false);
      }
    }
  }, [lastJsonMessage]);

  // useEffect(() => {
  //   if (!player) return;

  //   if (sound !== undefined && sound !== '/music/battle.mp3') {
  //     setSound!('/music/battle.mp3');
  //   }
  // }, [sound, player, setSound]);

  const handleClick = () => {
    if (isDisabled) return;

    // if (shadowBalance !== undefined && shadowBalance >= beat && balance >= beat) {
    if (shadowBalance !== undefined && current !== undefined && current > 0) {
      // labels.current.addLabel((new Date()).getTime(), Math.floor(Math.random() * 4 + 1));

      // setUserBeatCache((prev) => prev + beat);

      setIsDisabled(true);
      setProgress(true);
      // setLoading(true);

      sendJsonMessage({"method": "quest:beat"})

      if (navigator.vibrate) {
        navigator.vibrate([100]);
      }

      // setShadowBalance((bal) => (bal || balance) - beat);
      setCurrent((curr) => Math.max(curr - beat, 0))

      document.getElementById("hp-value")?.classList.add("active");
      setTimeout(() => {document.getElementById('hp-value')?.classList.remove('active');}, 1000);

      document.getElementById('boss')?.classList.add('beaten');
      setTimeout(() => {
        document.getElementById('boss')?.classList.remove('beaten');
      }, 1000);

      // setPctProgress(pctProgress)

    }
  };

  if (!initDataUnsafe) return <></>;

  return (
    <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
      {/* {celebration && (
        <Lottie
          animationData={pigeonsAnimation}
          loop={false}
          className="bg-animation"
        />
      )}

      <LazyLottie
        getAnimationData={() => import('../animations/fire.json')}
        id="quest-fire"
        loop={true}
        className="fire-anim"
      /> */}

      <div className="nemesis" id="boss" onClick={handleClick}>
        <img src={Pope} alt="" />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flex: 0,
          background: 'none transparent',
        }}
      >
        {/* <div
          className={'settings' + (mute ? ' off' : '')}
          onClick={startStopSound}
        >
          <Sound height={18} />
        </div> */}
        <div className="blessings blessings-faith">
          <Blessings height={18} />{' '}
          {user_id > 0 && shadowBalance !== undefined
            ? // <CountUp
              //   duration={2}
              //   separator={''}
              //   preserveValue={true}
              //   end={shadowBalance}
              // />
              shadowBalance
            : '...'}{' '}
          FAITH
        </div>
      </div>

      <div style={{ flex: '1 1 0' }}></div>

      <div style={{ flex: 0, height: '40px', zIndex: '1000' }}>
        <div className="quest__hp" id="hp-value"
          style={{
            marginLeft: '10px',
            fontSize: '82.5%',
            textShadow: '0 0 4px white',
            fontWeight: 500,
          }}
        >
          {current} / {total}
        </div>
        <div
          style={{
            width: 'calc(100vw - 22px)',
            margin: '0 12px 30px 10px',
            height: '6px',
            border: '1px solid #0c3d86',
            background: '#ffffffa0',
            borderRadius: '2px',
          }}
        >
          <div
            style={{
              width: `${pctProgress}%`,
              background: '#1d65d1',
              height: '6px',
              borderRadius: '1px',
            }}
          >
            &nbsp;
          </div>
        </div>
      </div>

      {/* {labels.current.getLabels().map((i) => (
        <div className={'damage2 path' + i[1]} key={i[0]}>
          -{beat}
        </div>
      ))} */}

      <div
        style={{ flex: 0 }}
        className={
          'main-btn' +
          (progress ? ' active' : '') +
          (isDisabled || loading || (shadowBalance || 0) < beat ? ' disabled' : '')
        }
        onClick={handleClick}
      >
        {progress && <div className="btn">CHARGING...</div>}
        {loading && <div className="btn loader"></div>}
        {!loading && !progress && !alarm && (
          <div
            className="btn"
            style={{ color: (shadowBalance || 0) < beat ? 'grey' : '#333' }}
          >
            FIGHT
          </div>
        )}
        {/*!loading && !progress && (
          <div className="btn2">
            <Blessings height={10} />
            {beat}
          </div>
        )*/}
        {!loading && progress && (
          <div
            className="progress"
            style={{ animation: `progress-quest ${beatTimeout + 1}s`, width: '100%' }}
          ></div>
        )}
      </div>
{/*
      <div className="ton-waves-quest"></div> */}
    </div>
  );
};
