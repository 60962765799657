import { useExpand, useInitData, useWebApp } from '@vkruglikov/react-telegram-web-app';
import './App.css';

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WebApp } from '@vkruglikov/react-telegram-web-app/lib/core/twa-types';
import { Suspense, lazy, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { FaithReceived } from './components/FaithReceived';
import { Onboarding } from './components/onboarding';
import { TaskBar } from './components/TaskBar';
import { ControlContext, defaultControlData } from './contexts/control';
import { TaskContext } from './contexts/task';
import { UserContext, defaultUserData } from './contexts/user';
import { InvitePage } from './pages/Invite';
import { BlessingPage } from './pages/Pray';
import { QuestPage } from './pages/Quest';
import { Message, StreamType, TaskContextType, TaskData, UserContextType } from './types';

type RequestAccessCallback = ((result: boolean) => void);

interface WebApp7_0 extends WebApp {
  requestWriteAccess(callback?: RequestAccessCallback): void;
}

interface WebApp7_7 extends WebApp {
  disableVerticalSwipes(): void;
}

type ActualWebApp = WebApp & Partial<WebApp7_0> & Partial<WebApp7_7>

const queryClient = new QueryClient();

const Rituals = lazy(() => import('./pages/rituals-page/rituals-page'));

function App() {
  const [isExpanded, expand] = useExpand();
  const [initDataUnsafe, initData] = useInitData();
  const [screenIdx, setScreenIdx] = useState(0);
  const [user, setUser] = useState<UserContextType>(defaultUserData);
  const [taskData, setTaskData] = useState<TaskContextType>({tasks_available: 0});
  const [control, setControl] = useState<StreamType>(defaultControlData.music);
  const [initDone, setInitDone] = useState(isExpanded);
  const [mode, setMode] = useState(-1)
  const [gameVersion, setGameVersion] = useState(-1)
  // const {mute, startStopSound, setSound, sound, player} = useMusic("/music/bg.mp3")
  const [tick, setTick] = useState(-1);
  const [pageState, setPageState] = useState(0);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const webApp: ActualWebApp = useWebApp();

  const { sendJsonMessage, readyState, lastJsonMessage } =
    useWebSocket<Message>(process.env.REACT_APP_WSS_ENDPOINT!, {
      onOpen: () => {
        console.log('WebSocket connection established.');
      },
      share: true,
      filter: (m) =>
        ['auth', 'user', 'tasks'].indexOf(JSON.parse(m.data).method) >= 0,
      retryOnError: true,
      shouldReconnect: () => true,
    });

  useEffect(() => {
    if (!initDataUnsafe) return;

    if (initDataUnsafe.start_param?.includes('pg_followers')) {
      setScreenIdx(2);
    } else if (initDataUnsafe.start_param?.includes('pg_rituals')) {
      setScreenIdx(1);
    }
  }, [initDataUnsafe]);

  useEffect(() => {
    if (!webApp) return;

    webApp.expand();
    setTimeout(() => {webApp.expand();}, 1500);

    webApp.setBackgroundColor!('#17212b');
    try {
      webApp.setHeaderColor!('#17212b');
    } catch (e)
    {
      webApp.setHeaderColor!('bg_color');
    }

    try {
      webApp.disableVerticalSwipes!();
    } catch (e) {

    }

    document.getElementsByTagName('html')[0].style.setProperty('--dvh', `${webApp.viewportStableHeight}px`);

    webApp.onEvent("viewportChanged", ({isStateStable}) => {
      if (isStateStable) {
        document
          .getElementsByTagName('html')[0]
          .style.setProperty('--dvh', `${webApp.viewportStableHeight}px`);
        setInitDone(true);
      }
    })
  }, [webApp])

  useEffect(() => {
    if (!initData || readyState !== ReadyState.OPEN) return;
    setPageState(0);
    sendJsonMessage({"method": "auth", "auth": initData})
  }, [initData, sendJsonMessage, readyState])

  useEffect(() => {
    if (!lastJsonMessage) return;
    if (lastJsonMessage.error) return;

    if (lastJsonMessage.method === "auth" && pageState === 0) {

      const gameInfo = lastJsonMessage.data as {
        game: number;
        version: number;
      };
      setMode(gameInfo.game || 0);
      setGameVersion(gameInfo.version || 0);

      setPageState(1);
      sendJsonMessage({"method": "user"})
      setTick(0)

    } else if (lastJsonMessage.method === "user" && pageState === 1) {
      const _user = lastJsonMessage.data as UserContextType;

      setUser(_user);

      const gameInfo = lastJsonMessage.data as { game: number, version: number };
      setMode(gameInfo.game || 0);
      setGameVersion(gameInfo.version || 0);

      setShowOnboarding(!_user.onboarding.status);

      ReactGA.set({ userId: _user.id });

    } else if (lastJsonMessage.method === "tasks") {
      const _tasks = lastJsonMessage.data as TaskData[];
      const cnt = _tasks.filter(
        (task) =>
          (task.special === null && !task.completed) || (task.special !== null && !task.claimed)
      ).length;
      setTaskData({tasks_available: cnt})
    }
  }, [lastJsonMessage, sendJsonMessage, pageState]);

  useEffect(() => {
    if (tick >= 0 && sendJsonMessage) {
      sendJsonMessage({ method: 'user' })
      sendJsonMessage({ method: 'tasks' });
    }

    setTimeout(() => setTick(tick + 1), 10000);
  }, [tick, sendJsonMessage])

  useEffect(() => {
    if (gameVersion < 0) return;

    const body: HTMLBodyElement = document.getElementsByTagName("body")[0];
    if (gameVersion === 1) {
      if (mode === 1) {
        body.classList.remove('pray-body');
        body.classList.add('quest-body');
      } else {
        body.classList.remove('quest-body');
        body.classList.add('pray-body');
      }
    } else if (gameVersion === 2) {
      body.classList.remove('quest-body');
      body.classList.add('pray-body');
    }
  }, [gameVersion, mode])

  useEffect(() => {
    if (!user || !webApp) return;

    if (!user.allows_pm) {
      try {
        webApp.requestWriteAccess!((result) => {
          if (result) {
            sendJsonMessage({"method": "update_user", "data": {"allows_pm": true}})
          }
        })
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === "WebAppWriteAccessRequested") {}
          else {
            console.error(err)
          }
        }
      }
    }
  }, [user, webApp, sendJsonMessage])

  // useEffect(() => {
  //   if (mute === undefined || !startStopSound) return;

  //   setControl({mute, startStopSound, setSound, sound, player});
  // }, [mute, setSound, startStopSound, sound, player])

  useEffect(() => {
    let page;
    switch (screenIdx) {
      case 0:
        page = {page: '/', title: 'Pray'}
        break;
      case 1:
        page = {page: '/rituals', title: 'Rituals'}
        break;
      case 2:
        page = {page: '/invites', title: 'Invites'}
        break
    }

    ReactGA.send({...page, hitType: 'pageview'});
  }, [screenIdx])

  useEffect(() => {
    if (isExpanded === undefined) return;

    setInitDone(isExpanded);
  }, [isExpanded])

  if (!initDone) return (
    <div
      style={{ width: '100vw', height: '100vh', backgroundColor: '#17212b' }}
    >
      <span className="page loader"></span>
    </div>
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ControlContext.Provider
        value={{
          music: control,
          game: { mode: mode, version: gameVersion },
          setScreenIdx,
        }}
      >
        <TaskContext.Provider value={taskData}>
          <UserContext.Provider value={user!}>
            <FaithReceived />
            <main>
              {showOnboarding && <Onboarding onHide={() => setShowOnboarding(false)} />}
              {screenIdx === 0 && mode === 1 && <QuestPage />}
              {screenIdx === 0 && mode === 0 && <BlessingPage />}
              {screenIdx === 1 && (
                <Suspense fallback={<span className="page loader"></span>}>
                  <Rituals />
                </Suspense>
              )}
              {screenIdx === 2 && <InvitePage />}
            </main>
            <TaskBar activeItem={screenIdx} onChange={setScreenIdx} />
          </UserContext.Provider>
        </TaskContext.Provider>
      </ControlContext.Provider>
    </QueryClientProvider>
  );
}

export default Sentry.withProfiler(App);
