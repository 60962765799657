import { ReactNode, Suspense, lazy } from "react";
import coinsAnimation from '../animations/coins.json';

export function NotificationsModal({
  show,
  text,
  coins,
  onClose,
}: {
  show: boolean;
  text: ReactNode;
  coins: boolean;
  onClose: () => void;
}) {

  if (!show) return <></>;

  const LottieL = lazy(() => import('lottie-react'));

  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    if ((e.target as HTMLElement).classList[0] !== "relics__overlay") {
      return;
    }

    e.stopPropagation();
    onClose();
  }

  return (
    <div className="relics__overlay">
      {coins && (
        <Suspense>
          <LottieL
            animationData={coinsAnimation}
            loop={true}
            className="coins-animation"
          />
        </Suspense>
      )}

      <div className="relics__wrapper">
        <div className="relics__title">Congratulations!</div>

        <div className="relics__description">{text}</div>

        <div className="relics__buttons">
          <button className="open" onClick={() => onClose()}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
}