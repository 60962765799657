import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { ReactComponent as StarSvg } from "./star.svg";

const starMovement = (endY: number) => keyframes`
    0% {
        opacity: 1;
        transform: translateY(0) scale(0.8);
    }
    100% {
        opacity: 0;
        transform: translateY(${endY}px) scale(1);
    }
`;

const Star = styled(StarSvg)<{ left: number; endY: number; delay: number; size: number }>`
    position: absolute;
    animation: ${({ endY }) => starMovement(endY)} 3s infinite ease-in-out;
    animation-delay: ${({ delay }) => delay}s;
    top: 100%;
    left: ${({ left }) => left}%;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
`;

const StarsContainer = styled.div`
    position: absolute;
    left: 32px;
    right: 32px;
    z-index: -1;
`;

export const StarsAnimation: React.FC = () => {
  const [stars, setStars] = useState<
    { id: number; left: number; endY: number; delay: number; size: number }[]
  >([]);

  useEffect(() => {
    const generateStars = () => {
      const numberOfStars = 40;
      const newStars = Array.from({ length: numberOfStars }, (_, i) => {
        const segmentWidth = 100 / numberOfStars;
        const left = (i * segmentWidth) + Math.random() * segmentWidth;
        const size = Math.random() > 0.5 ? 11.54 : 9;

        return {
          id: i,
          left,
          endY: Math.random() * -70 - 32,
          delay: Math.random() * 3,
          size,
        };
      });
      setStars(newStars);
    };

    generateStars();
  }, []);

  return (
    <StarsContainer>
      {stars.map((star) => (
        <Star
          key={star.id}
          left={star.left}
          endY={star.endY}
          delay={star.delay}
          size={star.size}
        />
      ))}
    </StarsContainer>
  );
};
