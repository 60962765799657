import { Children, forwardRef } from 'react';
import { Container } from './space.sc';
import { SpaceProps } from './types';

export const Space = forwardRef<HTMLDivElement, SpaceProps>((props, ref) => {
  const {
    children,
    align,
    size,
    wrap,
    column,
    width,
    shrink,
    inline,
    height,
    grow,
    justify,
    ...restProps
  } = props;

  const hasChildren = children && Children.toArray(children).length > 0;

  if (!hasChildren) {
    return null;
  }

  return (
    <Container
      ref={ref}
      $align={align}
      $size={size}
      $grow={grow}
      $inline={inline}
      $height={height}
      $width={width}
      $shrink={shrink}
      $wrap={wrap}
      $column={column}
      $justify={justify}
      {...restProps}>
      {children}
    </Container>
  );
});
