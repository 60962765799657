export function shuffleArray<T>(array: Array<T>) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export const formatFaithValue = (value: number) => {
  let x, suffix = "";
  if (value >= 1000000) {
    x = (value / 1000000).toFixed(1);
    const [a1, a2] = x.split(".");
    x = a1;
    suffix = `.${a2}M`;
  } else {
    x = value.toString();
  }

  const groups = x.match(/(\d+?)(?=(\d{3})+(?!\d)|$)/g) || [];
  return groups.join(' ') + suffix
}
