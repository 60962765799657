import { useEffect, useRef, useState } from "react";
import PopeGhost from "../images/pope_ghost.png";
import { shuffleArray } from "../utils";
export function Priests({nfts}: {nfts: number[]}) {
  const currentPriests = useRef<number[]>([]);
  const shownPriests = useRef<number[]>([]);

  const [newPriests, setNewPriests] = useState<Set<number>>();
  const [oldPriests, setOldPriests] = useState<Set<number>>();

  useEffect(() => {
    if (
      shownPriests.current === undefined ||
      currentPriests.current === undefined ||
      !shownPriests.current.length ||
      currentPriests.current.length !== (nfts || []).length
    ) {
      currentPriests.current = nfts || [];
      shuffleArray(currentPriests.current);
      shownPriests.current = currentPriests.current.slice(0, 7);
      // setNewPriests(new Set(shownPriests.current));
    }

    currentPriests.current = nfts || [];

    // const actual = new Set(currentPriests.current || []);
    // const _nfts = new Set(nfts);

    // const _new = _nfts.difference(actual);
    // const _old = actual.difference(_nfts);

    // if (_new.size !== 0 || _new.size !== 0) {
    //   shuffleArray(currentPriests.current);
    //   shownPriests.current = currentPriests.current.slice(0, 7);
    // }

    // setNewPriests(_new);
    // setOldPriests(_old);

    // for (let i=0; i<shownPriests.current.length; i++) {

    // }

  }, [nfts])

  return shownPriests.current.length === 0 ? (
    <div className="priest priest-ghost">
      <a href="https://getgems.io/collection/EQCju8teWRalVJk6Yl97u7PPLbVFImSGFzdVFZlf8zkhBEsZ" target="_blank" rel="noreferrer">
        <img src={PopeGhost} alt="Priest" />
      </a>
    </div>
  ) : (
    <div className="priests">
      {shownPriests.current.map((priest, idx) => {
        return (
          <div className={`priest priest-${idx + 1}`}>
            <img
              src={`https://static.tonchurch.io/nfts/priests/${priest}.png`}
              alt="Priest"
            />
          </div>
        );
      })}
    </div>
  );
}
