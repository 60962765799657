import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useCallback, useContext, useEffect, useState } from 'react';
import TextTransition, { presets } from 'react-text-transition';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { UserContext } from '../../contexts/user';
import { ReactComponent as Faith } from '../../images/stars.svg';
import { Message, TaskData } from '../../types';
import './index.css';


import img5 from '../../images/onboarding/chest.png';
import img9 from '../../images/onboarding/coins.png';
import img10 from '../../images/onboarding/friends-center.png';
import img1 from '../../images/onboarding/hell-bg.jpg';
import img7 from '../../images/onboarding/pasta.png';
import img3 from '../../images/onboarding/pop1.png';
import img4 from '../../images/onboarding/pop2.png';
import img6 from '../../images/onboarding/soton.png';
import img8 from '../../images/onboarding/sunduk.png';

interface TasksMessage extends Message {
  data: Array<TaskData>;
}

export function Onboarding({
  onHide,
}: {
  onHide: () => void;
}) {

  const [currentStep, setCurrentStep] = useState(0);
  const [checking, setChecking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [targetTask, setTargetTask] = useState<TaskData>();
  const [failed, setFailed] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);
  const WORDS = ['TONs  ', <span><Faith />FAITH</span>, 'NFTs  ', 'tokens', 'boosts'];


  const webApp = useWebApp();
  const { onboarding } = useContext(UserContext);

  const { sendJsonMessage, readyState, lastJsonMessage } =
    useWebSocket<TasksMessage>(process.env.REACT_APP_WSS_ENDPOINT!, {
      share: true,
      filter: (m) =>
        ['tasks'].indexOf(JSON.parse(m.data).method) >= 0,
      retryOnError: true,
      shouldReconnect: () => true,
    });

  useEffect(() => {
    const images = [img1, img3, img4, img5, img6, img7, img8, img9, img10]
    for (let i=0; i<images.length; i++) {
      new Image().src = images[i];
    }
  }, [])

  useEffect(() => {
    const intervalId = setInterval(
      () => setWordIndex((index) => index + 1),
      2000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  useEffect(() => {
    if (readyState !== ReadyState.OPEN || !sendJsonMessage) return;

    sendJsonMessage({ method: 'tasks' });
  }, [sendJsonMessage, readyState])

  useEffect(() => {
    if (!lastJsonMessage || lastJsonMessage.error) return;

    if (lastJsonMessage.method === 'tasks') {
      const data = lastJsonMessage.data;

      if (!onboarding.task_id) return;

      let task = undefined;
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === onboarding.task_id) {
          setTargetTask(data[i]);
          task = data[i];
          break;
        }
      }

      if (task) {
        if (task.claimed) {
          onHide();
        } else {
          setChecking(task.completed && !task.claimed);

          if (task.completed && !task.claimed) {
            if (loading) {
              setLoading(false);
              setFailed(true);
            }
          }
        }
      }
    }
  }, [lastJsonMessage, onboarding.task_id]);

  const handleClick = useCallback(() => {
    if (!targetTask) return;

    if (failed || !checking) {
      sendJsonMessage({ method: 'ritual_start', ritual_id: targetTask.id });

      if (targetTask.link.includes('https://t.me')) {
        webApp.openTelegramLink!(targetTask.link);
      } else {
        webApp.openLink!(targetTask.link);
      }

      setChecking(true);
      setFailed(false);

    } else {
      setLoading(true);
      // setTimeout(() => {setLoading(false)}, 3000);

      sendJsonMessage({ method: 'ritual_claim', ritual_id: targetTask.id });
    }

  }, [onboarding, checking, targetTask, sendJsonMessage])

  return (
    <div className="onboarding--base">
      <div className={currentStep === 2 ? ' onboarding--hell' : 'onboarding'}>
        <div className="onboarding__slide-indicator">
          {[0, 1, 2, 3, 4].map((i) => (
            <div
              key={`step-${i}`}
              className={
                'onboarding__slide-indicator-item' +
                (i === currentStep
                  ? ' onboarding__slide-indicator-item--active'
                  : '')
              }
            />
          ))}
        </div>

        <div className="onboarding__content">
          <div className="onboarding__lister">
            <div
              className="onboarding__lister__pane"
              onClick={() => {
                if (currentStep > 0) setCurrentStep((step) => step - 1);
              }}
            ></div>
            <div
              className="onboarding__lister__pane"
              onClick={() => {
                if (currentStep < 4) setCurrentStep((step) => step + 1);
              }}
            ></div>
          </div>
          {currentStep === 0 && (
            <>
              <div className="faith-increase">
                <div className="faith-increase__rays"></div>
                <div className="faith-increase__circle"></div>
                <span className="faith-increase__value">+500</span>
              </div>

              <div className="faith-growth">
                <h2 className="faith-growth__title">
                  Grow in <Faith />
                  FAITH
                </h2>
                <p className="faith-growth__description">
                  Collect <Faith />
                  FAITH every few hours
                </p>
              </div>
            </>
          )}

          {currentStep === 1 && (
            <>
              <div className="pop1"></div>
              <div className="pop2"></div>
              <div className="friends-bg popes-bg-shadow"></div>

              <div className="faith-growth">
                <h2 className="faith-growth__title">Build your church</h2>
                <p className="faith-growth__description">
                  Buy and collect priests
                </p>
              </div>
            </>
          )}

          {currentStep === 2 && (
            <>
              <div className="saton"></div>
              <div className="pasta"></div>
              <div className="sunduk"></div>

              <div className="faith-growth">
                <h2 className="faith-growth__title">Fight for your faith!</h2>
                <p className="faith-growth__description">
                  Defeat bosses and collect unique loot
                </p>
              </div>
            </>
          )}

          {currentStep === 3 && (
            <>
              <div className="chest"></div>
              <div className="chest-coins"></div>

              <div className="faith-growth">
                <h2 className="faith-growth__title">Acquire sacred relics</h2>
                <p className="faith-growth__description">
                  TONs, NFTs, tokens, boosts and <Faith />FAITH
                </p>
              </div>
            </>
          )}

          {currentStep === 4 && (
            <>
              <div className="friends-bg"></div>
              <div className="friends-center"></div>

              <div className="faith-growth">
                <h2 className="faith-growth__title">Invite friends</h2>
                <p className="faith-growth__description">
                  And earn more{' '}
                  <TextTransition
                    inline
                    direction="down"
                    className='onboarding__transitions'
                    springConfig={presets.default}
                    style={{ color: '#FFE500' }}
                  >
                    {WORDS[wordIndex % WORDS.length]}
                  </TextTransition>
                </p>
              </div>
            </>
          )}

          {currentStep < 4 && (
            <button
              className="onboarding__next-button"
              onClick={() => setCurrentStep((val) => val + 1)}
            >
              NEXT
            </button>
          )}

          {currentStep >= 4 && (
            <button className="onboarding__next-button" onClick={handleClick}>
              {loading ? (
                <span
                  style={{ display: 'inline-block' }}
                  className="line loader"
                ></span>
              ) : checking ? (
                'CHECK SUBSCRIPTION'
              ) : (
                'JOIN CHANNEL TO PROCEED'
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
