import styled, { css } from 'styled-components';

import { SpaceAlign, SpaceJustify } from './types';

export const getAlignStyles = (align?: SpaceAlign) => {
  switch (align) {
    case 'start':
      return css`
        align-items: flex-start;
      `;
    case 'end':
      return css`
        align-items: flex-end;
      `;
    case 'center':
    case 'baseline':
    case 'stretch':
      return css`
        align-items: ${align};
      `;
  }
};

const getJustifyStyles = (justify?: SpaceJustify) => {
  switch (justify) {
    case 'start':
      return css`
        justify-content: flex-start;
      `;
    case 'end':
      return css`
        justify-content: flex-end;
      `;
    case 'center':
    case 'space-between':
    case 'space-around':
    case 'stretch':
      return css`
        justify-content: ${justify};
      `;
    default:
      return;
  }
};

const getWrapStyles = (wrap?: boolean) =>
  wrap &&
  css`
    flex-wrap: wrap;
  `;

const getInlineStyles = (inline?: boolean) =>
  inline &&
  css`
    display: inline-flex;
    width: auto;
  `;

type StyledSpaceProps = {
  $column?: boolean;
  $size?: number;
  $align?: SpaceAlign;
  $justify?: SpaceJustify;
  $inline?: boolean;
  $wrap?: boolean;
  $grow?: boolean;
  $shrink?: boolean;
  $height?: number | string;
  $width?: number | string;
};

export const Container = styled.div<StyledSpaceProps>`
  display: flex;
  flex-wrap: nowrap;
  align-items: normal;

  ${({ $size }) => `gap: ${$size}px;`}
  ${({ $grow }) => $grow && `flex-grow: 1;`}
  ${({ $shrink = true }) => $shrink && `flex-shrink: 1;`}
  ${({ $column }) =>
    $column &&
    css`
      flex-direction: column;
    `}
  ${({ $height }) => $height && `height: ${$height}${typeof $height === 'number' ? 'px' : ''};`}
  ${({ $width }) => $width && `width: ${$width}${typeof $width === 'number' ? 'px' : ''};`}
  
  ${({ $align }) => getAlignStyles($align)}
  ${({ $justify }) => getJustifyStyles($justify)}
  ${({ $wrap }) => getWrapStyles($wrap)}
  ${({ $inline }) => getInlineStyles($inline)}
`;
