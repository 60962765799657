import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { WebApp } from '@vkruglikov/react-telegram-web-app/lib/core/twa-types';
import React, {CSSProperties, useCallback, useContext, useEffect, useState} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import styled, { css } from 'styled-components';
import { UserContext } from '../contexts/user';
import { ReactComponent as Blessings } from '../images/stars2.svg';
import { ReactComponent as Followers } from '../images/followers.svg';
import { ReactComponent as Sleep } from '../images/sleep.svg';
import {Button} from "../shared/components/button";
import {CopyIcon} from "../shared/components/copy-icon";
import {ListItemPlate} from "../shared/components/list-item-plate";
import {Space} from "../shared/components/space";
import {UnstyledButton} from "../shared/components/unstyled-button";
import { Message, ReferralType } from '../types';
import raysImageUrl from '../images/rays.svg'
import fishImageUrl from '../images/fish.png'
import {formatFaithValue} from "../utils";

const FOOTER_HEIGHT = 60;

const Container = styled.div`
    position: relative;
    padding: 12px 12px 0;
    background: var(--tgm-theme-bg-color);
    color: var(--tgm-theme-text-color) !important;
    display: flex;
    flex-direction: column;
    height: calc(var(--dvh,100dvh) - ${FOOTER_HEIGHT}px - env(safe-area-inset-bottom));
    box-sizing: border-box;
    overflow-y: auto;
`

const PromoStub = styled.div`
  padding-top: 170px;
  height: 100%;
  background: url(${() => raysImageUrl}) no-repeat, url(${() => fishImageUrl}) no-repeat;
  background-position: center top, center top 300px;
  background-size: 100% auto, 100% auto;
    font-weight: 600;
  
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const StatsPlate = styled.div`
    margin-bottom: 15px;
    padding: 16px;
    padding-bottom: 0;
    background-color: #2C3E50;
    border: 2px solid #000000;
    border-radius: 16px;
    box-shadow: 0px 0px 0px 2px #40566C inset;
`

const StatsPlateDivider = styled.div`
    margin: 0 -16px;
    margin-top: 11px;
    height: 1px;
    background: #40566C;
`

const StatsPlateBalance = styled.div`
    display: flex;
    align-items: center;
    height: 56px;
    gap: 8px;

    font-family: 'Oswald';
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.02em;
    text-align: left;
    color: white;
`

const SendInvitationButton = styled(Button).attrs({ $variant: 'blue', $size: 'xl', $shine: true })`
    position: sticky;
    margin-top: auto;
    bottom: 16px;
    width: 100%;
    flex-shrink: 0;
`

const FollowersNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #1286CE;
  color: white;
  letter-spacing: -0.02em;
  font-size: 12px;
    font-weight: 600;
`

const CopyButton = styled(UnstyledButton)`
    background-color: #333D47;
    border-radius: 40px;
    height: 30px;
    box-sizing: border-box;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    text-transform: uppercase;
    font-family: 'Oswald';
    font-size: 12px;
`

interface ReferralsMessage extends Message {
  data: Array<ReferralType>;
}

export const InvitePage = () => {
  const [textToCopy, setTextToCopy] = useState("");
  const [referrals, setReferrals] = useState<Array<ReferralType>>([]);
  const [copySuccess, setCopySuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tick, setTick] = useState(-1);

  const { id: user_id, invite_code, balance, pending, username  } = useContext(UserContext);

  const webApp: WebApp = useWebApp();

  const { sendJsonMessage, readyState, lastJsonMessage } =
    useWebSocket<ReferralsMessage>(process.env.REACT_APP_WSS_ENDPOINT!, {
      filter: (m) => ['referrals'].indexOf(JSON.parse(m.data).method) >= 0,
      share: true,
      shouldReconnect: () => true,
    });

  useEffect(() => {
    if (readyState !== ReadyState.OPEN || user_id === 0) return;

    sendJsonMessage({ method: 'referrals' });
  }, [user_id, sendJsonMessage, readyState]);

  useEffect(() => {
    if (tick >= 0 && sendJsonMessage) {
      sendJsonMessage({ method: 'referrals' });
    }

    setTimeout(() => setTick(tick + 1), 15000);
  }, [tick, sendJsonMessage]);

  useEffect(() => {
    if (!lastJsonMessage || lastJsonMessage.error) return;

    if (lastJsonMessage.method === 'referrals') {
      const data = lastJsonMessage.data || [];
      const self: ReferralType = {
        username: username ? <span>{username} <span style={{opacity: 0.5}}>it's you</span></span> : <b>You</b>,
        balance,
        referrals_count: 0,
        date_joined: '',
        inactive_from: null
      }

      setReferrals([...data, self].sort((a, b) => {
        if (a.inactive_from && !b.inactive_from) return 1;
        if (!a.inactive_from && b.inactive_from) return -1;
        return b.balance - a.balance;
      }));
      setLoading(false);
    }
  }, [balance, lastJsonMessage, username]);

  const handleClick = useCallback(() => {
    // if (referrals_left <= 0) return;

    webApp.openTelegramLink!(
      `https://t.me/share/url?url=${textToCopy}&text=Join me in the new TON Church, receive ✨150 FAITH as a welcome gift and find your enlightenment!`
    );
  }, [textToCopy, webApp]);

  const handleClaim = useCallback(() => {
    if (!sendJsonMessage || pending <= 0) return;

    sendJsonMessage({ method: 'referrals:claim' });
  }, [pending, sendJsonMessage])

  useEffect(() => {
    if (!invite_code) return;

    setTextToCopy(`${process.env.REACT_APP_MINIAPP_URL}?startapp=ref_${invite_code}`);
  }, [invite_code])

  const onCopy = (text: string, result: boolean) => {
    if (result) {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 1500);
    }
  }

  if (!webApp || user_id === 0) return <></>;

  const showPromoStub = !loading && !referrals.length;
  const showList = !loading && !!referrals.length;

  return (
    <>
      <Container>
        <Space style={{ fontWeight: 600, marginBottom: 12, lineHeight: '35px' }} align={'center'} justify={'space-between'}>
          <Space style={{ fontSize: 22 }} align={'center'} size={8}>
            <div>Followers</div>
            {!!referrals.length && <FollowersNumber>{referrals.length}</FollowersNumber>}
          </Space>
          <CopyToClipboard text={textToCopy} onCopy={onCopy}>
            <CopyButton>
              {copySuccess ?
                  <span><FontAwesomeIcon size="sm" icon={faCheckCircle}/>{' '}COPIED!</span>
                :
                  <><CopyIcon /><div>Copy link</div></>
              }
            </CopyButton>
          </CopyToClipboard>
        </Space>

        {showPromoStub && <PromoStub>
            <div>You will get</div>
            <PromisedReward fontSize={32} iconSize={35} />
            <div>every time new follower joins by your link as well as the follower himself.</div>
        </PromoStub>}

        {showList && <StatsPlate>
          <div>
              <span>Gain </span>
              <PromisedReward fontSize={16} iconSize={18} style={{ display: 'inline-flex', verticalAlign: '-3px' }} />
              <span> for every new follower through your link, and they get it too.</span>
          </div>
          <StatsPlateDivider />
          <StatsPlateBalance>
            <div onClick={handleClick}>{pending > 0 ? 'YOU EARNED' : 'INVITE MORE FRIENDS'}</div>

            {pending > 0 && (
               <>
                 <Space align={'center'} style={{ marginLeft: pending > 0 ? '' : 'auto'}}>
                   <Blessings height={24} width={24} fill={'#ECBF02'}/>
                   <span style={{ color: '#ECBF02'}}>{pending}&nbsp;FAITH</span>
                 </Space>
                 <Button
                   $variant={'green'}
                   style={{maxWidth: 100, flexGrow: 1, marginLeft: 'auto'}}
                   onClick={handleClaim}>
                   Claim
                 </Button>
               </>
            )}
          </StatsPlateBalance>
        </StatsPlate>}

        {showList && <Space column size={2} style={{paddingBottom: 16 + 16 + 52}}>
          {referrals.map((ref, i) => <ReferralPlate key={i} referral={ref} index={i} />)}
        </Space>}

        {loading && (
          <div style={{ flex: '1 1 26px' }}>
            <span className="page loader" />
          </div>
        )}

        {!loading && <SendInvitationButton onClick={handleClick}>
          SEND INVITATION
        </SendInvitationButton>}
      </Container>
    </>
  );
};

function PromisedReward({ fontSize, iconSize, style }: { fontSize: number, iconSize: number, style?: CSSProperties}) {
  return <Space style={{ color: '#4BCC36', ...style}} size={6} align='center'>
    <Blessings height={iconSize} width={iconSize} fill={'#4BCC36'} />
    <strong style={{ fontSize, fontFamily: 'Oswald'}}>+150 FAITH</strong>
  </Space>
}


const ReferralContainer = styled(ListItemPlate)<{ $disabled?: boolean }>`
    ${({ $disabled }) => $disabled && css`
    background: #202D3A;
      
    &:before {
        background: none;
    }
  `}
`

const ReferralNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #1286CE80;
  color: white;
  letter-spacing: -0.02em;
  flex-shrink: 0;
`

const ReferralBalance = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    font-family: 'Oswald';
    font-size: 20px;
    color: white;
    font-weight: 500;
    letter-spacing: -0.02em;
`

const Name = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

function ReferralPlate({ referral, index }: { referral: ReferralType, index: number }) {
  const disabled = !!referral.inactive_from

  return <ReferralContainer $disabled={disabled}>
    <ReferralNumber style={{ fontSize: index >= 99 ? 12 : 14, opacity: disabled ? 0.4 : 1 }}>
      {index + 1}
    </ReferralNumber>
    <Space style={{ overflow: 'hidden' }}>
      <Name style={{opacity: disabled ? 0.4 : 1 }}>{referral.username || 'Anonymous'}</Name>
      {referral.referrals_count > 0 && (
        <span style={{marginLeft: '.5rem', opacity: 0.5, whiteSpace: 'nowrap', flexShrink: 0 }}>
          +{referral.referrals_count}&nbsp;
          <Followers height={14} width={14} fill={'white'}/>
        </span>
      )}
      {referral.inactive_from && (
        <span style={{marginLeft: '.5rem', opacity: 0.5, whiteSpace: 'nowrap', flexShrink: 0 }}>
          <Sleep height={14} width={14} fill={'white'}/>
        </span>
      )}
    </Space>
    <ReferralBalance style={{ opacity: disabled ? 0.4 : 1 }}>
      <Blessings height={24} width={24} fill={'white'}/>
      &nbsp;{formatFaithValue(referral.balance)}
    </ReferralBalance>
  </ReferralContainer>
}
