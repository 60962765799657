import styled from "styled-components";
import glassImageUrl from '../../../images/glass_bg.png'

export const ListItemPlate = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 56px;
    box-sizing: border-box;
    padding: 16px;
    border: 2px solid #000000;
    border-radius: 16px;
    box-shadow: 0px 0px 0px 2px #40566C inset;
    font-family: "Open Sans";
    font-weight: 500;
    z-index: 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .1;
      z-index: -1;
      background: url(${() => glassImageUrl}) no-repeat;
      background-position-x: 44%;
      background-position-y: center;
      background-size: 100%;
      border-radius: 16px;
    }
`
