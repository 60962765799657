import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_GA_ID!,
    gaOptions: {
      send_page_view: false,
    },
  },
]);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/faith-bot\.tonchurch\.io/],
  beforeSend: (event) => {
    if (
      event.exception?.values?.[0]?.stacktrace?.frames?.some(
        (f) =>
          f.filename?.includes(`https://www.googletagmanager.com/`) ||
          f.module?.includes('ton-connect.error') ||
          f.module === 'sad'
      )
    ) {
      return null;
    }
    return event;
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
